import React from 'react';
import Layout from 'layout';
import 'sass/theme/terms.scss';

const Tos = () => (
  <Layout
    metaTitle="Terms of Service Attachement I - Fees and Subscription Plans"
    canonical="/terms-fees/"
  >
    <section className="terms">
      <h1>
        LIVESESSION SOFTWARE-AS-A-SERVICE <br />
        TERMS & CONDITIONS
      </h1>
      <h2>ATTACHMENT I – FEES AND SUBSCRIPTION PLANS</h2>

      <ol type="1">
        <li>
          <u>Subscription plans</u>
          <strong>.</strong> Use of LiveSession SaaS is fully subject to payment of Fees for a
          subscription plan agreed between the Parties, as indicated in the Subscription Form (“
          <strong>Subscription Plan</strong>
          ”). Client may choose one of the following Subscription Plans:
          <div className="scrollbox">
            <table>
              <thead>
                <tr>
                  <th>Plan name</th>
                  <th>Sessions limit per month</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Micro</td>
                  <td>5 000</td>
                </tr>
                <tr>
                  <td>Starter</td>
                  <td>10 000</td>
                </tr>
                <tr>
                  <td>Startup</td>
                  <td>25 000</td>
                </tr>
                <tr>
                  <td>Scale</td>
                  <td>50 000</td>
                </tr>
              </tbody>
            </table>
          </div>
          <strong>What is a session? </strong> <br />A session is defined as a group of interactions
          one user takes within one visit. Session ends after user is not active for 30 minutes.
          Meaning whatever a user does on your website (e.g. browses pages, clicks, move mouse,
          write on keyboard) before they leave equals one session. <br />
        </li>

        <li>
          <u>Cancelation</u>
          <strong>.</strong> LiveSession shall have a right to cancel any Subscription Plan at any
          time without stating a reason.{' '}
        </li>
      </ol>
    </section>
  </Layout>
);

export default Tos;
